<template>
  <div ref="test_container" class="d-flex">
    <div
      class="test-container"
      :class="{
        'test-container-on-scroll': window_top > 318 && evaluator_view,
      }"
    >
      <div
        class="container-buttons-instrument"
        :class="{
          'lock-instrument-space-between':
            test.is_locked && user && !user.groups.includes(6),
        }"
      >
        <div>
          <b-button
            v-if="
              (allows_crud &&
                evaluation &&
                !test.is_locked &&
                !preview_evaluation) ||
              (allows_crud &&
                evaluation &&
                test.is_locked &&
                !preview_evaluation &&
                user &&
                user.groups.includes(6))
                ? allows_crud
                : false
            "
            variant="primary"
            size="sm"
            class="mt-3 ml-2"
            @click="
              $bvModal.show(
                `modal-edit-instrument-${test.id}-${container_instrument_id}`
              )
            "
          >
            <b-icon-pencil-square class="pencil-icon"></b-icon-pencil-square>
            Editar
            {{ $getVisibleNames("evaluations2.test", false, "Instrumento") }}
          </b-button>
          <b-button
            v-if="
              allows_crud &&
              !preview_evaluation &&
              evaluation &&
              !evaluation.test_creation_is_locked &&
              replicateEvaluationsList.length > 0 &&
              ((sectionsProfessor.length > 0 && evaluation.scope == 1) ||
                [2, 3].includes(evaluation.scope))
            "
            variant="primary"
            size="sm"
            class="mt-3 ml-2"
            @click="openReplicateTest()"
          >
            <b-icon icon="files"></b-icon>
            Replicar
            {{ $getVisibleNames("evaluations2.test", false, "Instrumento") }}
          </b-button>
        </div>
        <div class="mt-3">
          <b-button
            v-if="
              !test.is_locked &&
              evaluation &&
              allows_crud &&
              user &&
              !user.groups.includes(6) &&
              !preview_evaluation
            "
            variant="primary"
            size="sm"
            class="lock-btn p-0 mr-2 noprint"
            v-b-tooltip.top.noninteractive.v-secondary="
              `Bloquear  ${$getVisibleNames(
                'evaluations2.test',
                false,
                'Instrumento'
              )}`
            "
            @click="changeStateTest(test)"
          >
            <b-icon-unlock-fill></b-icon-unlock-fill>
          </b-button>
          <b-button
            v-if="
              test.is_locked &&
              evaluation &&
              allows_crud &&
              user &&
              !user.groups.includes(6) &&
              !preview_evaluation
            "
            variant="primary"
            size="sm"
            class="lock-btn p-0 mr-2 noprint"
            v-b-tooltip.top.noninteractive.v-secondary="
              `Desbloquear  ${$getVisibleNames(
                'evaluations2.test',
                false,
                'Instrumento'
              )}`
            "
            @click="changeStateTest(test)"
          >
            <b-icon-lock-fill></b-icon-lock-fill>
          </b-button>
        </div>
      </div>
      <b-modal
        :id="`modal-edit-instrument-${test.id}-${container_instrument_id}`"
        :title="`Editar ${$getVisibleNames(
          'evaluations2.test',
          false,
          'Instrumento'
        )}`"
        hide-footer
        size="xl"
      >
        <NewInstrumentForm
          :evaluation_id="evaluation_id"
          :Instrument="test"
          :show_step="
            evaluation &&
            (evaluation.matter_evaluation != null ||
              evaluation.egress_profile_matter_evaluation != null)
          "
          :locked_instrument="test.is_locked"
          :show_delete_button="!existEvaluateeTest"
          :ClosedEvaluatee="existEvaluateeTest"
          @closeModal="
            $bvModal.hide(
              `modal-edit-instrument-${test.id}-${container_instrument_id}`
            )
          "
        ></NewInstrumentForm>
      </b-modal>
      <b-modal
        :id="`modal-copy-instrument-${test.id}-${container_instrument_id}`"
        :title="`Replicar ${$getVisibleNames(
          'evaluations2.test',
          false,
          'Instrumento'
        )} en otras evaluaciones.`"
        @hide="
          () => {
            selected_evaluations_ids = [];
          }
        "
        hide-footer
        size="lg"
      >
        <b-form-group
          :label="`Seleccione las evaluaciones a donde se van a replicar:`"
          label-for="selected_evaluations_ids"
          label-cols="0"
          label-cols-sm="auto"
          label-cols-md="auto"
          class="m-0 p-0 mb-2"
        >
          <v-select
            class="mt-1"
            id="selected_evaluations_ids"
            :placeholder="`Seleccione uno o varios para filtrar.`"
            track-by="id"
            label="full_name"
            size="sm"
            v-model="selected_evaluations_ids"
            :options="replicateEvaluationsList"
            :reduce="(replicateEvaluationsList) => replicateEvaluationsList.id"
            :disabled="!allows_crud"
            :dropdown-should-open="dropdownShouldOpen"
            multiple
            :selectable="
              (option) => !selected_evaluations_ids.includes(option.id)
            "
          >
            <template v-slot:no-options="{ search, searching }">
              <template v-if="searching">
                No se encontró resultados para <em>{{ search }}</em
                >.
              </template>
            </template>
            <template v-slot:option="option">
              {{ option.full_name }}
            </template>
          </v-select>
        </b-form-group>
        <div class="d-flex">
          <b-button
            class="ml-auto mr-0"
            size="sm"
            @click="replicateTests"
            :disabled="selected_evaluations_ids.length == 0"
          >
            Aplicar
          </b-button>
        </div>
      </b-modal>
      <EvaluateeTime
        v-if="evaluatee_view"
        :is_published="isPublishedTest"
        :test="test"
        :evaluatee_view="evaluatee_view"
        :can_answer="canAnswer()"
        :is_running_test="is_running_test"
        :finished_test="isFinishedTest"
      >
      </EvaluateeTime>
      <HeaderInstrument
        :class="{
          'd-none':
            isformatPartialTest ||
            isformatTransversalInformation ||
            isformatTransversalExam ||
            isformatCorrectAnswerPartial ||
            isformatCorrectAnswerTransversal,
        }"
        :instrument_id="test.id"
        :allows_crud="
          allows_crud && !test.is_locked && evaluation && !preview_evaluation
            ? allows_crud
            : false
        "
        :user_id="userId"
        :finished_test="isFinishedTest"
        :container_instrument_id="container_instrument_id"
        :evaluatee_view="evaluatee_view"
        :evaluator_view="evaluator_view"
        @change_preview_evaluation="previewEvaluation"
        :preview_evaluation="preview_evaluation"
        :isPrintingRubrica="isPrintingRubrica"
        :isPrintingInstrumento="isPrintingInstrumento"
      ></HeaderInstrument>
      <!-- Mensaje antes que comience la evaluación -->
      <template
        v-if="
          evaluatee_view && canAnswer() && !is_running_test && !isFinishedTest
        "
      >
        <h4>
          La evaluación comenzará el "<strong>{{
            $moment(test.start_time) | FormatToDateTime
          }}</strong
          >"
          <div class="time-label-start start-time-label-start">Dentro de:</div>
          <div class="time-card-start">
            <Timer :end_date="test.start_time" />
          </div>
        </h4>
      </template>
      <div v-if="evaluatee_view && !canAnswer()">
        <h4>Usted no tiene permiso para contestar</h4>
      </div>
      <b-button
        class="mt-4 mb-4"
        v-if="
          evaluatee_view && !evaluateeTest && is_running_test && canAnswer()
        "
        @click="startInstrument"
        variant="primary"
      >
        Iniciar
        {{ $getVisibleNames("evaluations2.test", false, "Instrumento") }}
      </b-button>
      <template
        v-if="
          !evaluatee_view ||
          (evaluatee_view && canAnswer() && is_running_test && evaluateeTest) ||
          (evaluatee_view && canAnswer() && isFinishedTest) ||
          evaluator_view
        "
      >
        <EvaluativeSituation
          :class="{
            'print-hide':
              isformatTransversalExam ||
              isformatCorrectAnswerPartial ||
              isformatCorrectAnswerTransversal,
          }"
          :instrument_id="test.id"
          :evaluation_situations="evaluation_situations"
          :matter_evaluation="matter_evaluation"
        ></EvaluativeSituation>

        <EvaluativeAgent
          :class="{
            'print-hide':
              isPrintingInstrumento ||
              isformatTransversalInformation ||
              isformatTransversalExam ||
              isformatCorrectAnswerPartial ||
              isformatCorrectAnswerTransversal,
            'print-show': isPrintingRubrica,
          }"
          :instrument_id="test.id"
          :matter_evaluation="matter_evaluation"
        ></EvaluativeAgent>
        <DuocSpecificationTable
          v-if="
            evaluation &&
            (evaluation.matter_evaluation != null ||
              evaluation.egress_profile_matter_evaluation != null ||
              evaluation.parent_evaluation) &&
            ((institution && institution.internal_use_id == 'duoc_uc') ||
              $debug_change_duoc)
          "
          :class="{
            'print-hide':
              isPrintingInstrumento ||
              (checked_transversal_specification_table &&
                isformatTransversalExam) ||
              isformatCorrectAnswerPartial ||
              isformatCorrectAnswerTransversal,
            'print-show': isPrintingRubrica,
          }"
          :container_instrument_id="container_instrument_id"
          :allows_crud="
            allows_crud && evaluation && !preview_evaluation
              ? allows_crud
              : false
          "
          :preview_evaluation="preview_evaluation"
          :instrument_id="test.id"
          :evaluation_criteria_micro_ids="evaluation_criteria_micro_ids"
        ></DuocSpecificationTable>
        <TestDescriptorsDraggable
          :class="{
            'print-hide':
              isPrintingInstrumento || isformatCorrectAnswerTransversal,
            'print-show': isPrintingRubrica,
          }"
          :preview_evaluation="preview_evaluation"
          :container_instrument_id="container_instrument_id"
          :test_id="test.id"
          :allows_crud="
            allows_crud && evaluation && !preview_evaluation
              ? allows_crud
              : false
          "
          :position_number="1"
        ></TestDescriptorsDraggable>
        <CorrectAnswerComponent
          class="d-none"
          :class="{
            'print-hide':
              isPrintingInstrumento ||
              isformatPartialTest ||
              isformatTransversalInformation ||
              isformatTransversalExam,
            'print-show':
              isformatCorrectAnswerPartial || isformatCorrectAnswerTransversal,
          }"
          :questions_list="questionList"
          :evaluatee_view="evaluatee_view"
        ></CorrectAnswerComponent>
        <div
          class="container-btn-print mb-2 mr-2 noprint"
          v-if="
            !allows_crud &&
            !evaluatee_view &&
            !evaluator_view &&
            institution &&
            institution.internal_use_id != 'duoc_uc'
          "
        >
          <b-dropdown
            split
            variant="primary"
            size="sm"
            @click="printInstrument"
          >
            <template #button-content>
              <div class="d-flex align-items-center">
                <b-icon icon="printer" class="mr-1"></b-icon>
                <span>
                  Imprimir
                  {{
                    $getVisibleNames("evaluations2.test", false, "Instrumento")
                  }}
                </span>
              </div>
            </template>
            <b-dropdown-form style="width: 285px"
              ><b-form-checkbox
                class="mt-1"
                v-model="checked_score"
                name="check-score"
                switch
              >
                Ocultar <b>Puntaje</b> al Imprimir
              </b-form-checkbox>
              <b-dropdown-divider></b-dropdown-divider>
              <b-form-checkbox
                class="mt-1"
                v-model="checked_weightning"
                name="check-weightning"
                switch
              >
                Ocultar <b>Ponderación</b> al Imprimir
              </b-form-checkbox></b-dropdown-form
            >
          </b-dropdown>
        </div>
        <div
          class="d-flex justify-content-left mb-2 mr-2 noprint"
          v-if="
            !evaluatee_view &&
            !evaluator_view &&
            !preview_evaluation &&
            ((evaluationSituation &&
              evaluationSituation.enable_questions &&
              allows_crud &&
              (evaluationSituation.enable_redaction_questions ||
                evaluationSituation.enable_simple_selection_questions ||
                evaluationSituation.enable_multiple_selection_questions ||
                evaluationSituation.enable_matching_questions)) ||
              questionList.length > 0)
          "
        >
          <b-button
            variant="primary"
            class="noprint"
            size="sm"
            @click="
              $bvModal.show(
                `modal-specification-table-test-${test.id}-${container_instrument_id}`
              )
            "
          >
            <div class="d-flex align-items-center">
              <b-icon icon="card-list" class="mr-1"></b-icon>
              <span>Tabla de Especificaciones</span>
            </div>
          </b-button>
          <b-modal
            :id="`modal-specification-table-test-${test.id}-${container_instrument_id}`"
            :title="`Tabla de Especificaciones`"
            hide-footer
            size="xl"
          >
            <NewSpecificationTable
              :allows_crud="allows_crud"
              :test="test"
              :evaluation_criteria_micro_ids="evaluation_criteria_micro_ids"
              :evaluation_situations="evaluation_situations"
            ></NewSpecificationTable>
          </b-modal>
        </div>
        <GroupsContainer
          v-if="
            test.allow_group_creation && evaluation && evaluation.scope == 1
          "
          :Test="test"
          :allows_crud="allows_crud"
          :container_instrument_id="container_instrument_id"
          :evaluatee_view="evaluatee_view"
          :evaluator_view="evaluator_view"
          :preview_evaluation="preview_evaluation"
          :user_id="userId"
        ></GroupsContainer>
        <BodyInstrument
          v-if="!(evaluatee_view && !isPublishedTest && isFinishedTest)"
          :key="'BodyInstrument-' + key_evaluation_criteria"
          ref="BodyInstrument"
          :evaluation_criteria_micro_ids="evaluation_criteria_micro_ids"
          :class="{
            'print-hide':
              isPrintingRubrica ||
              isformatTransversalInformation ||
              isformatCorrectAnswerPartial ||
              isformatCorrectAnswerTransversal,
            'print-show': isPrintingInstrumento,
          }"
          :container_instrument_id="container_instrument_id"
          :instrument_id="test.id"
          :allows_crud="
            allows_crud && evaluation && !preview_evaluation
              ? allows_crud
              : false
          "
          :is_published="isPublishedTest"
          :preview_evaluation="preview_evaluation"
          :checked_score="checked_score"
          :checked_weightning="checked_weightning"
          :evaluatee_view="evaluatee_view"
          :evaluator_view="evaluator_view"
          :is_running_test="is_running_test"
          :finished_test="isFinishedTest"
          :user_id="userId"
          :closedEvaluationScore="closedEvaluationScore"
          :requests_within_the_component="requests_within_the_component"
          :evaluation_situations="evaluation_situations"
          @is_fetch_completed="$emit('is_fetch_completed')"
        ></BodyInstrument>
        <TestDescriptorsDraggable
          v-if="
            !(evaluatee_view && !isPublishedTest && isFinishedTest) &&
            questionList.length > 0
          "
          :class="{
            'print-hide':
              isPrintingInstrumento || isformatCorrectAnswerTransversal,
            'print-show': isPrintingRubrica,
          }"
          :preview_evaluation="preview_evaluation"
          :container_instrument_id="container_instrument_id"
          :test_id="test.id"
          :allows_crud="
            allows_crud && evaluation && !preview_evaluation
              ? allows_crud
              : false
          "
          :position_number="2"
        ></TestDescriptorsDraggable>
        <div
          class="container-btn-print mb-2 mr-2 noprint"
          v-if="
            !allows_crud &&
            !evaluatee_view &&
            !evaluator_view &&
            institution &&
            institution.internal_use_id != 'duoc_uc'
          "
        >
          <b-button variant="primary" size="sm" @click="printRubric">
            <div class="d-flex align-items-center">
              <b-icon icon="printer" class="mr-1"></b-icon>
              <span
                >Imprimir
                {{ $getVisibleNames("evaluations2.rubric", false, "Pauta") }}
              </span>
            </div>
          </b-button>
        </div>
        <!-- <template
          v-if="
            test &&
            test.evaluatees.length > 1 &&
            evaluatee_view &&
            evaluative_agent &&
            is_running_test &&
            !isFinishedTest &&
            !isPublishedTest &&
            (['duoc_coevaluacion_evaluative_agent'].includes(
              evaluative_agent.internal_use_id
            ) ||
              $equals(evaluative_agent.name, 'Coevaluación')) &&
            evaluateeRubric &&
            evaluateeRubric.coevaluators.length > 0
          "
        >
          <template v-for="coevaluator_id in evaluateeRubric.coevaluators">
            <RubricsContainer
              :key="
                'RubricsContainer-' +
                key_evaluation_criteria +
                '-' +
                coevaluator_id
              "
              :evaluation_criteria_micro_ids="evaluation_criteria_micro_ids"
              :class="{
                'print-hide':
                  isPrintingInstrumento ||
                  (checked_rubric_partial && isformatPartialTest) ||
                  isformatTransversalInformation ||
                  (checked_rubric_transversal && isformatTransversalExam) ||
                  isformatCorrectAnswerTransversal,
                'print-show': isPrintingRubrica,
              }"
              class="mb-4"
              :container_instrument_id="container_instrument_id"
              :instrument_id="test.id"
              :allows_crud="
                allows_crud && evaluation && !preview_evaluation
                  ? allows_crud
                  : false
              "
              :preview_evaluation="preview_evaluation"
              :evaluator_view="evaluator_view"
              :evaluatee_view="evaluatee_view"
              :is_running_test="is_running_test"
              :finished_test="isFinishedTest"
              :user_id="userId"
              :coevaluator_id="coevaluator_id"
              :closedEvaluationScore="closedEvaluationScore"
              :requests_within_the_component="requests_within_the_component"
            ></RubricsContainer>
          </template>
        </template> -->
        <!-- <template v-else> -->
        <!-- v-if="!(evaluatee_view && !isPublishedTest && isFinishedTest)" -->
        <div class="d-flex">
          <b-button
            class="ml-auto mr-0 mb-3"
            variant="primary"
            v-if="evaluatee_view && !isPublishedTest && rubric"
            @click="
              () =>
                (show_rubric_container_by_evaluatee =
                  !show_rubric_container_by_evaluatee)
            "
          >
            <template v-if="!show_rubric_container_by_evaluatee">
              Mostrar
            </template>
            <template v-else> Ocultar </template>
            {{ $getVisibleNames("evaluations2.rubric", false, "Pauta") }}
          </b-button>
        </div>
        <template
          v-if="
            !evaluatee_view ||
            show_rubric_container_by_evaluatee ||
            isPublishedTest
          "
        >
          <RubricsContainer
            :key="'RubricsContainer-' + key_evaluation_criteria"
            :evaluation_criteria_micro_ids="evaluation_criteria_micro_ids"
            :class="{
              'print-hide':
                isPrintingInstrumento ||
                (checked_rubric_partial && isformatPartialTest) ||
                isformatTransversalInformation ||
                (checked_rubric_transversal && isformatTransversalExam) ||
                isformatCorrectAnswerTransversal,
              'print-show': isPrintingRubrica,
            }"
            class="mb-4"
            :container_instrument_id="container_instrument_id"
            :instrument_id="test.id"
            :allows_crud="
              allows_crud && evaluation && !preview_evaluation
                ? allows_crud
                : false
            "
            :preview_evaluation="preview_evaluation"
            :evaluator_view="evaluator_view"
            :evaluatee_view="evaluatee_view"
            :is_running_test="is_running_test"
            :finished_test="isFinishedTest"
            :is_published="isPublishedTest"
            :user_id="userId"
            :closedEvaluationScore="closedEvaluationScore"
            :requests_within_the_component="requests_within_the_component"
          ></RubricsContainer>
        </template>
        <!-- :coevaluator_id="userId" -->
        <!-- </template> -->
        <div
          v-if="
            evaluatee_view &&
            test.student_can_upload_attachments &&
            evaluateeTest
          "
          class="mb-4"
        >
          <template v-if="evaluateeTest.student_attachments.length > 0">
            <div class="d-flex justify-content-center">
              <FileContainer
                :Files="evaluateeTest.student_attachments"
                :allows_crud="!isFinishedTest"
                @deleteFile="slotDeleteStudentFile"
              ></FileContainer>
            </div>
          </template>
          <b-button
            v-if="!isFinishedTest"
            @click="
              $bvModal.show(
                `modal-select-file-student-${test.id}-${container_instrument_id}`
              )
            "
            size="sm"
            class="mt-1"
            >Subir Archivo</b-button
          >
          <b-modal
            :id="`modal-select-file-student-${test.id}-${container_instrument_id}`"
            title="Seleccionar Archivos"
            hide-footer
          >
            <FileSelector @created="slotCreatedStudentFile"></FileSelector>
          </b-modal>
        </div>
        <b-button
          v-if="
            evaluatee_view &&
            canAnswer() &&
            is_running_test &&
            evaluateeTest &&
            !evaluateeTest.is_finished
          "
          @click="closeInstrument"
          variant="primary"
          class="mb-4 mt-3"
        >
          Finalizar
          {{ $getVisibleNames("evaluations2.test", false, "Instrumento") }}
        </b-button>
      </template>
    </div>
    <template v-if="evaluator_view">
      <div
        class="noprint question-test-score"
        :class="{
          'sticky-container': window_top > 318 && evaluator_view,
        }"
      >
        <EvaluatorTestScore
          :test="test"
          :user_id="userId"
          :evaluatorCanQualify="closedEvaluationScore"
          @previous_tabs="$emit('previous_tabs')"
          @next_tabs="$emit('next_tabs')"
        ></EvaluatorTestScore>
      </div>
    </template>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { customToast } from "@/utils/utils";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
  name: "ContainerInstrument",
  components: {
    vSelect,
    EvaluativeAgent: () => import("./EvaluativeAgent"),
    EvaluativeSituation: () => import("./EvaluativeSituation"),
    HeaderInstrument: () => import("./HeaderInstrument"),
    GroupsContainer: () => import("./Groups/GroupsContainer"),
    DuocSpecificationTable: () =>
      import(
        "@/components/new-evaluations/Evaluation/SpecificationTable/DuocSpecificationTable"
      ),
    NewSpecificationTable: () =>
      import(
        "@/components/new-evaluations/Evaluation/SpecificationTable/NewSpecificationTable"
      ),
    BodyInstrument: () => import("./BodyInstrument"),
    CorrectAnswerComponent: () => import("./CorrectAnswerComponent"),
    RubricsContainer: () =>
      import("@/components/new-evaluations/Rubrics/RubricsContainer"),
    TestDescriptorsDraggable: () =>
      import(
        "@/components/new-evaluations/Evaluation/TestDescriptorsDraggable"
      ),
    NewInstrumentForm: () => import("./NewInstrumentForm"),
    EvaluateeTime: () =>
      import(
        "@/components/new-evaluations/Evaluation/Evaluatee/EvaluateeTime.vue"
      ),
    EvaluatorTestScore: () =>
      import(
        "@/components/new-evaluations/Evaluation/Evaluators/EvaluatorTestScore.vue"
      ),
    Timer: () => import("@/components/reusable/Timer.vue"),
    FileSelector: () => import("@/components/reusable/FileSelector"),
    FileContainer: () => import("@/components/reusable/FileContainer"),
  },
  props: {
    test: {
      type: Object,
      required: true,
    },
    key_evaluation_criteria: {
      type: Number,
    },
    preview_evaluation: {
      type: Boolean,
    },
    allows_crud: {
      type: Boolean,
    },
    evaluation_id: {
      type: Number,
      required: true,
    },
    container_instrument_id: {
      type: String,
    },
    evaluatee_view: {
      type: Boolean,
    },
    evaluator_view: {
      type: Boolean,
      default: false,
    },
    isPrintingRubrica: {
      type: Boolean,
    },
    isformatPartialTest: {
      type: Boolean,
    },
    isformatCorrectAnswerPartial: {
      type: Boolean,
    },
    isformatCorrectAnswerTransversal: {
      type: Boolean,
    },
    isformatTransversalInformation: {
      type: Boolean,
    },
    isformatTransversalExam: {
      type: Boolean,
    },
    checked_rubric_partial: {
      type: Boolean,
    },
    checked_rubric_transversal: {
      type: Boolean,
    },
    checked_transversal_specification_table: {
      type: Boolean,
    },
    isPrintingInstrumento: {
      type: Boolean,
    },
    evaluation_criteria_micro_ids: {
      type: Array,
    },
    // Para que solo uno de los componentes pida las peticiones y el otro solo sirva para el imprimir todos los test en la misma línea.
    // y eso se ejecutaria solo si es !evaluatee_view && !evaluator_view
    requests_within_the_component: {
      type: Boolean,
      default: true,
    },
    // para mostrar el resultado del evaluado al profesor.
    user_id: {
      type: Number,
      required: false,
    },
    evaluation_situations: {
      type: Array,
    },
    matter_evaluation: {
      type: Object,
      default: null,
    },
    checked_score: {
      type: Boolean,
    },
    checked_weightning: {
      type: Boolean,
    },
  },
  data() {
    return {
      time_out: null,
      window_top: 0,
      // test_container_height: 0,
      is_running_test: false,
      finished_test: false,
      show_rubric_container_by_evaluatee: false,
      selected_evaluations_ids: [],
    };
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      institution: "getInstitution",
      evaluations: names.NEW_EVALUATIONS,
      sections: names.SECTIONS,
      test_segments: names.NEW_TEST_SEGMENTS,
      redaction_questions: names.NEW_REDACTION_QUESTIONS,
      multiple_choice_questions: names.NEW_MULTIPLE_CHOICE_QUESTIONS,
      // filling_questions: names.NEW_FILLING_QUESTIONS,
      matching_questions: names.NEW_MATCHING_QUESTIONS,
      evaluatee_matching_questions: names.EVALUATEE_MATCHING_QUESTIONS,
      evaluatee_matching_question_units:
        names.EVALUATEE_MATCHING_QUESTION_UNITS,
      evaluatee_evaluations: names.EVALUATEE_EVALUATIONS,
      evaluatee_tests: names.EVALUATEE_TESTS,
      rubrics: names.NEW_RUBRIC_INSTRUMENTS,
      evaluatee_rubrics: names.EVALUATEE_RUBRICS,
      evaluative_agents: names.NEW_TEST_TYPES,
      evaluatee_redaction_questions: names.EVALUATEE_REDACTION_QUESTIONS,
      evaluatee_multiple_choice_questions:
        names.EVALUATEE_MULTIPLE_CHOICE_QUESTIONS,
      evaluatee_multiple_choice_question_options:
        names.EVALUATEE_MULTIPLE_CHOICE_QUESTION_OPTIONS,
    }),
    evaluation() {
      return this.evaluations.find((x) => x.id == this.evaluation_id);
    },
    evaluationSituation() {
      if (!this.test) return null;
      return this.evaluation_situations.find(
        (x) => x.id == this.test.evaluation_situation
      );
    },
    existEvaluateeEvaluation() {
      if (
        this.evaluatee_evaluations.filter(
          (x) => x.evaluation == this.evaluation_id
        ).length > 0
      )
        return true;
      else return false;
    },
    existEvaluateeTest() {
      if (
        this.evaluatee_tests.filter(
          (x) =>
            x.test == this.test.id &&
            (x.start_time != null ||
              x.is_corrected ||
              x.is_finished ||
              x.is_published)
        ).length > 0
      )
        return true;
      else return false;
    },
    section() {
      if (this.evaluation && this.evaluation.scope == 1)
        return this.sections.find((x) => x.id == this.evaluation.scope_id);
      else return null;
    },
    closedEvaluationScore() {
      if (this.evaluator_view && this.user) {
        if (
          !this.section &&
          (this.test.evaluators.includes(this.user.id) ||
            this.user.is_superuser ||
            this.user.groups.includes(1))
        )
          return true;
        else if (
          this.section &&
          this.section.is_active &&
          (this.test.evaluators.includes(this.user.id) ||
            this.section.professors.includes(this.user.id) ||
            this.user.is_superuser ||
            this.user.groups.includes(1))
        )
          return true;
        else return false;
      } else return false;
    },
    userId() {
      if (this.evaluator_view) return this.user_id;
      else return this.user.id;
    },
    rubric() {
      if (!this.test) return null;
      return this.rubrics.find((x) => x.test == this.test.id);
    },
    evaluateeRubric() {
      if (this.evaluatee_view && this.user && this.rubric)
        return this.evaluatee_rubrics.find(
          (x) => x.evaluatee == this.user.id && x.rubric == this.rubric.id
        );
      return null;
    },
    evaluative_agent() {
      if (!this.test) return null;
      return this.evaluative_agents.find(
        (x) => this.test.evaluative_agent == x.id
      );
    },
    redactionQuestions() {
      return this.redaction_questions.filter((x) => x.test == this.test.id);
    },
    multipleChoiceQuestions() {
      return this.multiple_choice_questions.filter(
        (x) => x.test == this.test.id
      );
    },
    // fillingQuestions() {
    //   return this.filling_questions.filter((x) => x.test == this.test.id);
    // },
    matchingQuestions() {
      return this.matching_questions.filter((x) => x.test == this.test.id);
    },
    questionList() {
      let list = [];
      list = list.concat(
        this.redactionQuestions.map((question) => ({
          ...question,
          question_type: 1,
          segment_order:
            (this.test_segments.find((s) => s.id == question.segment) || {})
              .order || null,
        }))
      );
      list = list.concat(
        this.multipleChoiceQuestions.map((question) => ({
          ...question,
          question_type: 2,
          segment_order:
            (this.test_segments.find((s) => s.id == question.segment) || {})
              .order || null,
        }))
      );
      // list = list.concat(
      //   this.fillingQuestions.map((question) => ({
      //     ...question,
      //     question_type: 3,
      //     segment_order:
      //       (this.test_segments.find((s) => s.id == question.segment) || {}).order ||
      //       null,
      //
      //   }))
      // );
      list = list.concat(
        this.matchingQuestions.map((question) => ({
          ...question,
          question_type: 4,
          segment_order:
            (this.test_segments.find((s) => s.id == question.segment) || {})
              .order || null,
        }))
      );
      return list.sort(function (a, b) {
        if (a.segment_order < b.segment_order) return -1;
        if (a.segment_order > b.segment_order) return 1;
        if (a.order < b.order) return -1;
        if (a.order > b.order) return 1;
        return 0;
      });
    },
    evaluateeTest() {
      return this.evaluatee_tests.find(
        (x) => x.evaluatee == this.userId && x.test == this.test.id
      );
    },
    isFinishedTest() {
      if (
        this.finished_test ||
        (this.evaluateeTest && this.evaluateeTest.is_finished)
      )
        return true;
      else return false;
    },
    isPublishedTest() {
      if (this.evaluateeTest && this.evaluateeTest.is_published) return true;
      else return false;
    },
    questionsUnanswered() {
      let questions_unanswer = [];
      //  redaction question
      const redaction_questions_test_ids = this.redactionQuestions.map(
        (x) => x.id
      );
      const redaction_question_ids = this.evaluatee_redaction_questions
        .filter(
          (x) =>
            x.answer != "" &&
            x.evaluatee == this.user.id &&
            redaction_questions_test_ids.includes(x.redaction_question)
        )
        .map((x) => x.redaction_question);
      questions_unanswer = questions_unanswer.concat(
        this.questionList.filter(
          (x) =>
            !redaction_question_ids.includes(x.id) &&
            redaction_questions_test_ids.includes(x.id) &&
            x.question_type == 1
        )
      );
      // multiple choice questions
      const multiple_choice_question_test_ids =
        this.multipleChoiceQuestions.map((x) => x.id);
      const multiple_choice_question_ids =
        this.evaluatee_multiple_choice_questions
          .filter(
            (x) =>
              multiple_choice_question_test_ids.includes(
                x.multiple_choice_question
              ) &&
              x.evaluatee == this.user.id &&
              this.evaluatee_multiple_choice_question_options.some(
                (op) => op.evaluatee_multiple_choice_question == x.id
              )
          )
          .map((x) => x.multiple_choice_question);
      questions_unanswer = questions_unanswer.concat(
        this.questionList.filter(
          (x) =>
            !multiple_choice_question_ids.includes(x.id) &&
            multiple_choice_question_test_ids.includes(x.id) &&
            x.question_type == 2
        )
      );
      // matching questions
      const matching_question_test_ids = this.matchingQuestions.map(
        (x) => x.id
      );
      const matching_question_ids = this.evaluatee_matching_questions
        .filter(
          (x) =>
            matching_question_test_ids.includes(x.matching_question) &&
            x.evaluatee == this.user.id &&
            this.evaluatee_matching_question_units.some(
              (op) => op.evaluatee_matching_question == x.id
            )
        )
        .map((x) => x.matching_question);
      questions_unanswer = questions_unanswer.concat(
        this.questionList.filter(
          (x) =>
            !matching_question_ids.includes(x.id) &&
            matching_question_test_ids.includes(x.id) &&
            x.question_type == 4
        )
      );
      return questions_unanswer.sort(function (a, b) {
        if (a.segment_order > b.segment_order) return -1;
        if (a.segment_order < b.segment_order) return 1;
        if (a.order < b.order) return -1;
        if (a.order > b.order) return 1;
        return 0;
      });
    },
    sectionsProfessor() {
      if (this.evaluation && this.evaluation.scope == 1 && this.user)
        return this.sections.filter((x) => x.professors.includes(this.user.id));
      else return [];
    },
    replicateEvaluationsList() {
      if (!this.evaluation) return [];
      if (this.evaluation.scope == 1) {
        const sections_professor = this.sectionsProfessor.map((x) => x.id);
        return this.evaluations
          .filter(
            (x) =>
              x.scope == 1 &&
              sections_professor.includes(x.scope_id) &&
              x.parent_evaluation == this.evaluation.parent_evaluation
          )
          .map((x) => {
            const section_name = this.sectionsProfessor.find(
              (a) => a.id == x.scope_id && x.scope === 1
            );
            return {
              ...x,
              full_name: section_name
                ? section_name.name + " - " + x.title
                : x.title,
            };
          })
          .sort((a, b) =>
            a.full_name.toLowerCase().localeCompare(b.full_name.toLowerCase())
          );
      } else if ([2, 3].includes(this.evaluation.scope)) {
        return this.evaluations
          .filter(
            (x) =>
              x.scope == this.evaluation.scope &&
              x.scope_id == this.evaluation.scope_id
          )
          .map((x) => ({ ...x, full_name: x.title }))
          .sort((a, b) =>
            a.full_name.toLowerCase().localeCompare(b.full_name.toLowerCase())
          );
      } else return [];
    },
  },
  methods: {
    onScroll(e) {
      this.window_top = e.target.documentElement.scrollTop;
    },
    startInstrument() {
      if (this.test) {
        this.$swal({
          title: "¿Desea comenzar a responder el instrumento?",
          text: "La realización de este instrumento debe contar con la autorización o consentimiento por parte del estudiante.",
          type: "warning",
          showCancelButton: true,
          confirmButtonText: "Comenzar",
          cancelButtonText: "Cancelar",
        }).then((result) => {
          if (result.value) {
            this.$store.dispatch(names.POST_EVALUATEE_TEST, {
              is_finished: false,
              start_time: this.$moment().format("YYYY-MM-DD HH:mm:ss"),
              evaluatee: this.userId,
              test: this.test.id,
              attachments: [],
              student_attachments: [],
              evaluator_attachments: [],
            });
          }
        });
      }
    },
    closeInstrument() {
      if (this.evaluateeTest) {
        if (
          this.questionsUnanswered.length > 0 ||
          (this.test &&
            this.test.student_can_upload_attachments == true &&
            this.evaluateeTest.student_attachments.length == 0)
        ) {
          let message = "";
          if (this.questionsUnanswered.length > 0) {
            message += `Te faltan por responder las siguientes preguntas: </br> <Strong>${this.questionsUnanswered
              .map((x) =>
                x.segment_order != null
                  ? x.segment_order + "." + x.order
                  : x.order
              )
              .join(", ")}</Strong> </br>`;
          }
          if (
            this.test.student_can_upload_attachments == true &&
            this.evaluateeTest.student_attachments.length == 0
          ) {
            message += "Aún no ha adjuntado ningún archivo.";
          }
          this.$swal({
            title:
              "<p>¿Está seguro/a de que desea <strong>finalizar</strong> el instrumento?</p>",
            html: message,
            type: "warning",
            showCancelButton: true,
            confirmButtonText: "Estoy seguro/a",
            cancelButtonText: "Seguir respondiendo",
          }).then((result) => {
            if (result.value) {
              let payload = {
                evaluatee_test_id: this.evaluateeTest.id,
                item: {
                  is_finished: true,
                },
              };
              this.$store
                .dispatch(names.PATCH_EVALUATEE_TEST, payload)
                .then(() => {
                  customToast(
                    "Sus respuestas han sido enviadas satisfactoriamente. Gracias por contestar el instrumento.",
                    "bottom-left",
                    5000
                  );
                });
            } else {
              if (this.questionsUnanswered.length > 0) {
                const ref = this.$refs["BodyInstrument"];
                if (ref && typeof ref.scrollToQuestion === "function") {
                  ref.scrollToQuestion({
                    segment_id: this.questionsUnanswered[0].segment,
                    options: { behavior: "smooth", block: "center" },
                    question: this.questionsUnanswered[0],
                  });
                } else {
                  console.error("Referencia no encontrada");
                }
              }
            }
          });
        } else {
          this.$swal({
            title:
              "<p>¿Está seguro/a de que desea <strong>finalizar</strong> el instrumento?</p>",
            text: "¡Sus respuestas serán enviadas y esta acción no se podrá revertir!",
            type: "warning",
            confirmButtonText: "Sí",
            cancelButtonText: "No",
            showCancelButton: true,
          }).then((result) => {
            if (result.value) {
              let payload = {
                evaluatee_test_id: this.evaluateeTest.id,
                item: {
                  is_finished: true,
                },
              };
              this.$store
                .dispatch(names.PATCH_EVALUATEE_TEST, payload)
                .then(() => {
                  customToast(
                    "Sus respuestas han sido enviadas satisfactoriamente. Gracias por contestar el instrumento.",
                    "bottom-left",
                    5000
                  );
                });
            }
          });
        }
      }
    },
    canAnswer() {
      if (!this.test || !this.userId) return false;
      return (
        this.test.evaluatees.includes(this.userId) ||
        (this.section && this.section.students.includes(this.userId))
      );
    },
    changeStateTest(test) {
      const payload = {
        new_test_id: test.id,
        item: {
          is_locked: !test.is_locked,
        },
      };
      this.$store.dispatch(names.PATCH_NEW_TEST, payload);
    },
    previewEvaluation() {
      this.$emit("change_preview_evaluation");
    },
    printInstrument() {
      this.$emit("print_instrument");
    },
    printRubric() {
      this.$emit("print_rubric");
    },
    slotCreatedStudentFile(file) {
      if (file && this.evaluateeTest) {
        if (!isNaN(this.evaluateeTest.id)) {
          let student_attachments = this.evaluateeTest.student_attachments;
          student_attachments.push(file.id);
          this.$store.dispatch(names.PATCH_EVALUATEE_TEST, {
            evaluatee_test_id: this.evaluateeTest.id,
            item: {
              student_attachments: student_attachments,
            },
          });
        }
      }
      this.$bvModal.hide(
        `modal-select-file-student-${this.test.id}-${this.container_instrument_id}`
      );
    },
    slotDeleteStudentFile(file_id) {
      if (this.evaluateeTest)
        this.$store.commit("MUTATE_DELETE_STUDENT_FILE_IN_EVALUATEE_TEST", {
          evaluatee_test_id: this.evaluateeTest.id,
          file_id: file_id,
        });
    },
    dropdownShouldOpen(VueSelect) {
      if (this.replicateEvaluationsList.length > 0) {
        return VueSelect.open;
      }
      return VueSelect.search.length !== 0 && VueSelect.open;
    },
    async replicateTests() {
      if (this.selected_evaluations_ids.length > 0 && this.test) {
        for await (let evaluation_id of this.selected_evaluations_ids) {
          await this.$restful
            .Get(
              `/evaluations2/copy_test_and_set_evaluation/?test=${this.test.id}&to_evaluation=${evaluation_id}`
            )
            .then((test_id) => {
              if (this.evaluation && this.evaluation.id == evaluation_id) {
                this.$store.dispatch(names.FETCH_NEW_TEST, test_id);
                this.$store.dispatch(names.FETCH_NEW_RUBRIC_INSTRUMENTS, {
                  test_id: test_id,
                });
                this.$store.dispatch(
                  names.FETCH_NEW_TEST_EVALUATION_CRITERIA_MICROS,
                  {
                    test_id: test_id,
                  }
                );
              }
            });
        }
        this.$bvModal.hide(
          `modal-copy-instrument-${this.test.id}-${this.container_instrument_id}`
        );
      }
    },
    openReplicateTest() {
      if (this.evaluation) {
        if (this.sectionsProfessor.length > 0 && this.evaluation.scope == 1) {
          this.$store.dispatch(names.FETCH_NEW_EVALUATIONS, {
            scoped_id: 1,
            scoped_object_ids: this.sectionsProfessor.map((x) => x.id),
            parent_evaluation_id: this.evaluation.parent_evaluation,
          });
        } else if ([2, 3].includes(this.evaluation.scope)) {
          this.$store.dispatch(names.FETCH_NEW_EVALUATIONS, {
            scoped_id: this.evaluation.scope,
            scoped_object_id: this.evaluation.scope_id,
          });
        }
      }
      this.$bvModal.show(
        `modal-copy-instrument-${this.test.id}-${this.container_instrument_id}`
      );
    },
  },
  mounted() {
    this.window_top = window.scrollY;
    // Inicia el temporizador
    if (this.evaluatee_view) {
      const now = () => this.$moment();
      const start_time = this.$moment(this.test.start_time);
      const end_time = this.$moment(this.test.end_time);
      const time_to_run = start_time - now();
      // Abrir la pag antes de que inicie
      if (time_to_run > 0) {
        this.time_out = setTimeout(() => {
          const current_time = now();
          if (current_time >= start_time && current_time <= end_time) {
            this.is_running_test = true;
            const time_to_end = end_time - current_time;
            this.time_out = setTimeout(() => {
              const current_time = now();
              if (current_time >= end_time) {
                this.is_running_test = false;
                this.finished_test = true;
              }
            }, time_to_end);
          }
        }, time_to_run); // ajusta este tiempo según sea necesario
      } else {
        // Abrir la pag dentro del Rango
        const current_time = now();
        if (current_time >= start_time && current_time <= end_time) {
          this.is_running_test = true;
          const time_to_end = end_time - current_time;
          this.time_out = setTimeout(() => {
            const current_time = now();
            if (current_time >= end_time) {
              this.is_running_test = false;
              this.finished_test = true;
            }
          }, time_to_end);
        } else if (current_time >= end_time) {
          // Abrir la pag cuando la evaluación finalizo.
          this.is_running_test = false;
          this.finished_test = true;
        }
      }
    }
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
    // Limpia el temporizador al destruir el componente
    if (this.time_out) {
      clearTimeout(this.time_out);
    }
  },
  created() {
    window.addEventListener("scroll", this.onScroll);
    if (
      (this.evaluatee_view && this.userId && this.test) ||
      (this.evaluator_view && this.userId && this.test)
    ) {
      this.$store.dispatch(names.FETCH_EVALUATEE_TESTS, {
        evaluatee_id: this.userId,
        test_id: this.test.id,
      });
      this.$store.dispatch(names.FETCH_EVALUATEE_REDACTION_QUESTIONS, {
        evaluatee_id: this.userId,
        test_id: this.test.id,
      });
      this.$store.dispatch(names.FETCH_EVALUATEE_MULTIPLE_CHOICE_QUESTIONS, {
        evaluatee_id: this.userId,
        test_id: this.test.id,
      });
      this.$store.dispatch(
        names.FETCH_EVALUATEE_MULTIPLE_CHOICE_QUESTION_OPTIONS,
        {
          evaluatee_id: this.userId,
          test_id: this.test.id,
        }
      );
      this.$store.dispatch(names.FETCH_EVALUATEE_MATCHING_QUESTIONS, {
        evaluatee_id: this.userId,
        test_id: this.test.id,
      });
      this.$store.dispatch(names.FETCH_EVALUATEE_MATCHING_QUESTION_UNITS, {
        evaluatee_id: this.userId,
        test_id: this.test.id,
      });
      this.$store.dispatch(names.FETCH_EVALUATEE_EVALUATIONS, {
        evaluatee_id: this.userId,
        evaluation_id: this.evaluation_id,
      });
      this.$store
        .dispatch(names.FETCH_EVALUATEE_RUBRICS, {
          evaluatee_id: this.userId,
          test_id: this.test.id,
        })
        .then((evaluatee_rubrics) => {
          if (this.evaluatee_view && this.is_running_test) {
            const evaluatee_users = [
              ...new Set(
                evaluatee_rubrics
                  .map((x) => x.coevaluators)
                  .flat()
                  .concat([this.userId])
              ),
            ];
            this.$store
              .dispatch(
                names.FETCH_EVALUATEE_RUBRIC_CRITERIA_PERFORMANCE_LEVELS,
                {
                  evaluatees_ids: evaluatee_users,
                  test_id: this.test.id,
                }
              )
              .then((response) => {
                const eva_rub_cri_per_lev_ids = response.map((x) => x.id);
                if (eva_rub_cri_per_lev_ids.length > 0)
                  this.$store.dispatch(
                    names.FETCH_EVALUATEE_RUBRIC_CRITERIA_PERFORMANCE_LEVEL_EVALUATORS,
                    {
                      evaluatee_rubric_criteria_performance_levels_ids:
                        eva_rub_cri_per_lev_ids,
                    }
                  );
              });
          } else {
            this.$store
              .dispatch(
                names.FETCH_EVALUATEE_RUBRIC_CRITERIA_PERFORMANCE_LEVELS,
                {
                  evaluatee_id: this.userId,
                  test_id: this.test.id,
                }
              )
              .then((response) => {
                const eva_rub_cri_per_lev_ids = response.map((x) => x.id);
                if (eva_rub_cri_per_lev_ids.length > 0)
                  this.$store.dispatch(
                    names.FETCH_EVALUATEE_RUBRIC_CRITERIA_PERFORMANCE_LEVEL_EVALUATORS,
                    {
                      evaluatee_rubric_criteria_performance_levels_ids:
                        eva_rub_cri_per_lev_ids,
                    }
                  );
              });
          }
        });
    }
  },
};
</script>

<style scoped>
.time-label-start {
  color: white;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 4px;
  margin-right: 7px;
}
.start-time-label-start {
  background-color: rgb(235, 70, 70);
}
.time-card-start {
  margin-right: 10px;
  font-size: 150px;
}
.lock-btn {
  border: 1px;
  height: 27px;
  width: 27px;
}
.container-btn-print {
  display: flex;
  justify-content: right;
}

.container-buttons-instrument {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}
.lock-instrument-space-between {
  display: flex;
  justify-content: space-between !important;
}

.b-dropdown-form {
  padding: 0;
}
.test-container {
  width: 100%;
}
.question-test-score {
  width: 25%;
  padding-top: 3em;
  background: var(--kl-menu-color);
  color: white;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.test-container-on-scroll {
  width: 80%;
}
.sticky-container {
  max-width: 20%;
  position: fixed;
  top: 100px;
  right: 0;
  height: 100% !important;
  z-index: 1;
}
.print-show {
  display: block !important;
}
@media (min-width: 2000px) {
  .sticky-container {
    top: 8%;
    height: 36%;
  }
}
@media print {
  .print-hide {
    display: none !important;
  }
  .print-show {
    display: block !important;
  }
}
</style>

